/** @format */

import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useFormik } from 'formik';
import ButtonSyncLoader from 'components/ButtonSyncLoader';
import { useTranslation } from 'react-i18next';
import { formikHelper } from 'utils/formikHelper';
import Input from 'components/Forms/Input';
import NameBlock from 'components/Forms/NameBlock';
import AddressBlock from 'components/Forms/AddressBlock';
import AlertBlock from 'components/Forms/AlertBlock';
import ModalWrapper from '..';

export default function ValuationOfMyHome(props) {
  const { t } = useTranslation();
  const text = t(
    'lang_common:object_listing:modals:forms:valuationOfMyHomeForm'
  );

  const [formError, setFormError] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formSuccess, setFormSuccess] = useState(false);
  const [disableValuationButton, setDisableValuationButton] = useState(false);
  const { customerId, open, openValuationOfMyHome } = props;

  const formikHook = useFormik;

  const initialValues = {
    firstName: '',
    lastName: '',
    socialSecurityNumber: '',
    email: '',
    contactNo: '',
    streetAddress: '',
    zipCode: '',
    city: '',
    message: '',
    customerId: customerId
  };

  const mandatoryFields = [
    'firstName',
    'lastName',
    'email',
    'contactNo',
    'streetAddress',
    'zipCode',
    'city',
    'message'
  ];

  const formik = formikHelper(
    formikHook,
    initialValues,
    setFormSubmitted,
    setFormError,
    setFormSuccess,
    'valuationOfMyHome',
    mandatoryFields
  );

  useEffect(() => {
    let emptyFieldCounter = 0;
    mandatoryFields.forEach((item) => {
      // check if field is empty
      if (formik.values[item]?.length < 1) {
        emptyFieldCounter = emptyFieldCounter + 1;
      }
    });
    if (emptyFieldCounter > 0) {
      setDisableValuationButton(true);
    } else {
      setDisableValuationButton(false);
    }
  }, [formik]);

  const closeModal = () => {
    openValuationOfMyHome(false);
  };

  return (
    <>
      <ModalWrapper
        heading={text.heading?.label}
        description={text.description}
        openModal={closeModal}
        open={open}>
        <form onSubmit={formik.handleSubmit}>
          <AlertBlock
            formError={formError}
            formErrorMessage={text.formErrorMessage?.validation}
            formSuccess={formSuccess}
            formSuccessMessage={text.formSuccessMessage}
          />
          <NameBlock
            formikValues={formik.values}
            formikHandleChange={formik.handleChange}
            text={text}
            formSubmitted={formSubmitted}
          />

          <Row>
            <Input
              identifier='socialSecurityNumber'
              type='text'
              value={formik.values.socialSecurityNumber}
              handleChange={formik.handleChange}
              label={text.socialSecurityNumber?.label}
              disabled={formSubmitted}
            />
            <Input
              identifier='contactNo'
              type='text'
              required={true}
              value={formik.values.contactNo}
              handleChange={formik.handleChange}
              label={text.contactNo?.label}
              disabled={formSubmitted}
            />
          </Row>
          <Row>
            <Input
              identifier='email'
              type='email'
              required={true}
              value={formik.values.email}
              handleChange={formik.handleChange}
              label={text.email?.label}
              disabled={formSubmitted}
            />
          </Row>

          <AddressBlock
            formikValues={formik.values}
            formikHandleChange={formik.handleChange}
            text={text}
            formSubmitted={formSubmitted}
          />
          <Row>
            <Col className='modal-field-container'>
              <label htmlFor='message' className='text3m required-label'>
                {text.message?.label}
              </label>
              <textarea
                id='message'
                name='message'
                type='test'
                onChange={formik.handleChange}
                value={formik.values.message}
                placeholder={text.message?.label}
                className='modal-field-textarea contactUs-description'
                disabled={formSubmitted}
              />
            </Col>
          </Row>
          <div className='modal-button-container'>
            <Button
              className={'btn btn-pink mobile-modal-button-width'}
              type='submit'
              disabled={formSubmitted || disableValuationButton}>
              {formSubmitted ? <ButtonSyncLoader /> : text.submitButton?.label}
            </Button>
          </div>
        </form>
      </ModalWrapper>
    </>
  );
}
