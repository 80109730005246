/** @format */

import React from 'react';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import config from 'config';

export default function MobileHeader(props) {
  const { show, setShow, buttonText, getButtonColor } = props;
  const { brokerLoginUrl } = config.app;

  return (
    <div className='mobile-header-right-right-block'>
      <div
        className='menu-switch'
        data-target='#basic-nav-dropdown'
        data-toggle='hamburger-dropdown show'
        onClick={() => setShow(!show)}>
        {show ? (
          <div className='hamburger-close' />
        ) : (
          <div className='hamburger-menu' />
        )}
      </div>
      <Navbar bg='none'>
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='me-auto'>
            <div
              aria-expanded='true'
              className={`hamburger-dropdown  dropdown nav-item ${
                show ? 'show' : ''
              }`}>
              <Link
                aria-haspopup='true'
                aria-expanded={show ? 'true' : 'false'}
                id='basic-nav-dropdown'
                to='#'
                className='dropdown-toggle nav-link'
                role='button'></Link>
              <div
                aria-labelledby='basic-nav-dropdown'
                className={`dropdown-menu ${show ? 'show' : ''} `}>
                <div className='menu-wrapper'>
                  <div className='mobile-header-right-button-container'>
                    {buttonText.map((item, key) => (
                      <div className={`mobile-header-button-item`} key={key}>
                        {key === 0 ? (
                          <a href={brokerLoginUrl}>
                            <Button
                              className={`btn btn-${getButtonColor(key)}`}>
                              {item.text}
                            </Button>
                          </a>
                        ) : (
                          <Link to={item.props.url}>
                            <Button
                              className={`btn btn-${getButtonColor(key)}`}>
                              {item.text}
                            </Button>
                          </Link>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}
