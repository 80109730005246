/** @format */

import React from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import CustomButton from 'components/CustomButton';
import FormField from 'components/Forms/FormField';
import { getNumericValue } from 'utils/geNumericValue';
import ViewCalculationModal from 'components/Modals/ViewCalculationModal';
import {
  calculationUtils,
  calculateFinalResult
} from 'views/Registration/HomePage/FeeCalculator/calculator';

export default function FeeCalculator(props) {
  const { tprop } = props;
  const { t } = useTranslation();

  const [getValue, setValue] = React.useState('');
  const [showModal, setShowModal] = React.useState(false);

  const onChange = (e) => {
    setValue(getNumericValue(e));
  };

  const initialValues = {
    salary: getValue
  };

  const validationSchema = () =>
    Yup.object({
      salary: Yup.string()
    });

  const calculatedResult = t(
    'lang_registration:homepage:feeCalculator.result.description.text',
    { amount: calculateFinalResult(getValue) }
  );

  const viewCalculationModalHeading = t(
    'lang_registration:homepage:feeCalculator.result.breakdown.heading.text',
    { amount: getValue ? getValue : 0 }
  );

  const viewCalculationModalFooter = t(
    'lang_registration:homepage:feeCalculator.result.breakdown.footer.text',
    { amount: calculateFinalResult(getValue) }
  );

  return (
    <div className='fee-calculator-container'>
      <div className='heading-container'>
        <h3>{tprop.heading.text}</h3>
      </div>
      <div className='description-container'>
        <p className='text2r'>{tprop.description.text}</p>
      </div>
      <div className='input-container'>
        <div className='input-box'>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema()}
            onChange={onChange}>
            {() => (
              <Form className='form-group validate-email-form'>
                <FormField
                  name='salary'
                  className='form-control text2r'
                  onChange={(e) => onChange(e.target.value)}
                  placeholder={tprop.input.placeholder.text}
                  value={getValue}
                  autoComplete='false'
                />
              </Form>
            )}
          </Formik>
        </div>
        <div className='input-label'>
          <label className='text3m'>{tprop.input.label.text}</label>
        </div>
      </div>
      <div className='result-container'>
        <p className='text3m'>{tprop.result.heading.text}</p>
        <p className='text1 resultText'>{calculatedResult}</p>
        <div className=' view-calculation text4'>
          <a to='#' onClick={() => setShowModal(true)}>
            {tprop.result.link.text}
          </a>

          <ViewCalculationModal
            showModal={showModal}
            setShowModal={setShowModal}
            heading={viewCalculationModalHeading}
            description={tprop.result.breakdown.items}
            footer={viewCalculationModalFooter}
            amount={getValue}
            calculationUtils={calculationUtils}
          />
        </div>
      </div>

      <CustomButton
        text={tprop.buttons.text}
        url={tprop.buttons.url}
        isBgPink={true}
      />
    </div>
  );
}
