/** @format */

import axios from 'axios';
import appConfig from 'config/app';

/**
 * Common function to call Fria Maklare API endpoints
 *
 * @param url - Api endpoint to call ex:- healthcheck, registerBroker etc
 * @param httpType - API endpoint HTTP type to use ex:- GET, POST, PUT, DELETE
 * @param data - data object to be passed to the API
 * @param auth -boolean- whether auth params to be included or not - default false
 * @param requestContentType - Request content type ex:- application/json, multipart/formdata etc
 *        default is application/json
 *
 * @returns Object
 *
 * @author Sandun Munasinghe
 * @since 16-08-2022
 */
const callFriaMaklareApi = async (
  endpointUrl,
  httpType = 'GET',
  data = null,
  auth = false,
  requestContentType = 'application/json'
) => {
  let requestParamsObj = {
    timeout: 10000
  };

  const url = `${appConfig.baseApiUrl}/${endpointUrl}`;
  const accessToken = '';

  if (httpType == 'GET' || httpType == 'DELETE') {
    if (data) {
      requestParamsObj.params = data;
    }

    if (auth) {
      requestParamsObj.headers = {
        Authorization: `Bearer ${accessToken}`
      };
    }
    if (httpType == 'GET') {
      return axios.get(url, requestParamsObj);
    } else {
      return axios.delete(url, requestParamsObj);
    }
  } else if (httpType == 'POST' || httpType == 'PUT') {
    let postRequestObj = {
      method: httpType.toLowerCase(),
      url,
      headers: {
        'Content-Type': requestContentType
      }
    };
    if (data) {
      postRequestObj.data = data;
    }

    if (auth) {
      postRequestObj.headers = {
        ...postRequestObj.headers,
        Authorization: `Bearer ${accessToken}`
      };
    }

    return axios(postRequestObj);
  }
};

/**
 *
 * Calls the register broker API in Fria Maklare
 *
 * @param {*} firstName
 * @param {*} lastname
 * @param {*} email
 *
 * @author Sandun Munasinghe
 * @since 16-08-2022
 */
export const registerUserApiService = async (firstName, lastName, email) => {
  return callFriaMaklareApi('registerBroker', 'POST', {
    firstName,
    lastName,
    email
  });
};

export const onboardBrokerService = async (formData) => {
  return callFriaMaklareApi(
    'onboardBroker',
    'PUT',
    formData,
    false,
    'multipart/form-data'
  );
};

export const fetchBrokerProfileService = async ({ brokerId, profileRef }) => {
  const response = await callFriaMaklareApi(
    'brokerData',
    'GET',
    { brokerId, profileRef },
    false
  );
  return response;
};

export const fetchBrokerProfileBySlugService = async ({
  brokerSlug,
  profileRef
}) => {
  const response = await callFriaMaklareApi(
    'brokerDataBySlug',
    'GET',
    { brokerSlug, profileRef },
    false
  );
  return response;
};

export const fetchBrokerObjectsService = async ({
  brokerId,
  profileRef,
  page = 1
}) => {
  const response = await callFriaMaklareApi(
    'listBrokerObjects',
    'GET',
    { brokerId, profileRef, page },
    false
  );
  return response;
};
export const fetchBrokerObjectsBySlugService = async ({
  brokerSlug,
  profileRef,
  page = 1
}) => {
  const response = await callFriaMaklareApi(
    'listBrokerObjectsBySlug',
    'GET',
    { brokerSlug, profileRef, page },
    false
  );
  return response;
};

export const fetchBrokerObjectService = async ({ objectId, profileRef }) => {
  const response = await callFriaMaklareApi(
    'objectData',
    'GET',
    { objectId, profileRef },
    false
  );
  return response;
};

export const fetchViewingTimeSlotsService = async ({
  customerId,
  objectId
}) => {
  const response = await callFriaMaklareApi(
    `viewingTimeSlots?customerId=${customerId}&objectId=${objectId}`,
    'GET',
    false
  );
  return response;
};
